import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { Size } from '../../types/size';
import { NcIcon } from './icon.types';

@Component({
  selector: 'nc-icon',
  templateUrl: './icon.component.html',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  styleUrl: './icon.component.scss',
})
export class NcIconComponent {
  @Input() icon!: NcIcon;
  @Input() size: Size = 'md';
}
