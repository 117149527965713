import { Component, Input } from '@angular/core';

import { NcIcon, NcIconComponent } from '../icon';

@Component({
  // For accessibility, the host element must be a <button> element
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[nc-nav-button]',
  standalone: true,
  imports: [NcIconComponent],
  templateUrl: './nav-button.component.html',
  styleUrl: './nav-button.component.scss',
})
export class NcNavButtonComponent {
  @Input() icon: NcIcon;
}
