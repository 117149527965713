import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
} from '@azure/msal-browser';
import { environment } from '@clinician_environment';

const isIE =
  window.navigator.userAgent.includes('MSIE') ||
  window.navigator.userAgent.includes('Trident/');
export const b2cPolicies = {
  names: {
    signUpSignIn: environment.b2cTenant.signInFlowName,
  },
  authorities: {
    signUpSignIn: {
      authority: environment.b2cTenant.signInAuthority,
    },
  },
  authorityDomain: environment.b2cTenant.authorityDomain,
};
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.b2cTenant.clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (_logLevel, message) => {
        // eslint-disable-next-line no-console
        console.log(message);
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  clinicianApi: {
    endpoint: environment.apiURL + '/api/*',
    scopes: environment.b2cTenant.scopes,
  },
};

export const loginRequest = {
  scopes: [],
};
