<mat-icon
  [svgIcon]="icon"
  [ngClass]="{
    'size-4': size === 'xs',
    'size-5': size === 'sm',
    'size-6': size === 'md',
    'size-7': size === 'lg',
    'size-8': size === 'xl',
    'size-12': size === '2xl',
  }"
></mat-icon>
