import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { PermissionsService } from '@clinician/services/permissions/permissions.service';
import { environment } from '@clinician_environment';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  switchMap,
} from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

export type FeatureFlagKeys = 'tms' | 'questionnaires' | 'sleep';

export type FeatureFlagResponse = {
  [K in FeatureFlagKeys]: boolean;
};

@Injectable({ providedIn: 'root' })
export class FeatureFlagService implements OnDestroy {
  private destroyed$ = new Subject<void>();

  private features: Record<FeatureFlagKeys, BehaviorSubject<boolean>> = {
    tms: new BehaviorSubject<boolean>(false),
    questionnaires: new BehaviorSubject<boolean>(false),
    sleep: new BehaviorSubject<boolean>(false),
  };

  constructor(
    private readonly httpClient: HttpClient,
    private readonly permissionsService: PermissionsService,
  ) {
    this.permissionsService.clinicGroupId$$
      .pipe(
        filter((clinicGroupId): clinicGroupId is string => !!clinicGroupId),
        switchMap((clinicGroupId) => this.loadFeatureFlags(clinicGroupId)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  loadFeatureFlags(clinicGroupId: string) {
    return this.httpClient
      .get<FeatureFlagResponse>(
        `${environment.featureFlagsURL}/${clinicGroupId}/flags.json`,
      )
      .pipe(
        tap((features) => {
          Object.entries(features).forEach(([key, value]) => {
            const featureKey = key as FeatureFlagKeys;
            this.setFeature(featureKey, value);
          });
        }),
      );
  }

  setFeature(feature: FeatureFlagKeys, value: boolean): void {
    if (this.features[feature].value !== value) {
      this.features[feature].next(value);
    }
  }

  toggleFeature(feature: FeatureFlagKeys): void {
    this.setFeature(feature, !this.features[feature].value);
  }

  getFeature(feature: FeatureFlagKeys): Observable<boolean> {
    return this.features[feature].asObservable();
  }

  getFeatures(): Observable<Record<FeatureFlagKeys, boolean>> {
    return combineLatest(
      Object.entries(this.features).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: value.asObservable(),
        };
      }, {}),
    );
  }
}
