import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
} from '@angular/material/snack-bar';

import { NotificationType } from '../../types/notification-type';
import { NcButtonComponent } from '../button/button.component';
import { NcIcon, NcIconComponent } from '../icon';
import { NcSnackBarData } from './snackbar.types';

type NcSnackBarDataWithCloseAction = NcSnackBarData & { dismiss: () => void };

@Component({
  selector: 'nc-snackbar',
  standalone: true,
  imports: [
    CommonModule,
    MatSnackBarLabel,
    NcButtonComponent,
    MatSnackBarActions,
    MatSnackBarAction,
    NcIconComponent,
  ],
  templateUrl: './snackbar.component.html',
})
export class NcSnackBarComponent {
  iconMapping: { [P in NotificationType]: NcIcon } = {
    success: NcIcon.CircleCheckColored,
    error: NcIcon.CircleCrossColored,
    warning: NcIcon.TriangleWarningColored,
    info: NcIcon.CircleInfoColored,
  };

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NcSnackBarDataWithCloseAction,
  ) {}

  dismiss() {
    this.data.dismiss();
  }

  protected readonly NcIcon = NcIcon;
  protected readonly Array = Array;
}
