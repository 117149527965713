import { Component, Injector, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  ClinicReportClaim,
  PatientsClaim,
} from '@clinician/common/enums/claims';
import { FeatureFlagService } from '@clinician/services/core/feature-flag.service';
import { PermissionsService } from '@clinician/services/permissions/permissions.service';
import { environment } from '@clinician_environment';
import { NcIcon } from '@neurocare/ui';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-navigation-panel',
  templateUrl: './navigation-panel.component.html',
})
export class NavigationPanelComponent implements OnInit {
  authService: MsalService;
  patientsAccess$: Observable<boolean>;
  clinicReportAccess$: Observable<boolean>;
  baseVersion$: Observable<boolean>;
  NcIcon = NcIcon;

  constructor(
    readonly permissionsService: PermissionsService,
    readonly featureFlagService: FeatureFlagService,
    readonly injector: Injector,
  ) {
    if (environment.b2cTenant.enabled) {
      this.authService = this.injector.get(MsalService);
    }
  }

  logout(): void {
    if (environment.b2cTenant.enabled) {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: '/patient',
      });
    }
  }

  ngOnInit(): void {
    this.clinicReportAccess$ = this.permissionsService.clinicReport$$.pipe(
      map((permission) => permission !== ClinicReportClaim.NoAccess),
    );
    this.patientsAccess$ = this.permissionsService.patients$$.pipe(
      map((permission) => permission !== PatientsClaim.NoAccess),
    );
    this.baseVersion$ = this.featureFlagService
      .getFeatures()
      .pipe(
        map(
          (features) =>
            !features.tms && !features.sleep && !features.questionnaires,
        ),
      );
  }
}
