import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { repeat, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StopwatchService {
  maxTimerInMinutes = 30;
  currentTimer: number;
  timer = timer(60000);

  constructor(readonly authService: MsalService) {}

  startTimer(): void {
    this.currentTimer = this.maxTimerInMinutes;
    this.timer.pipe(repeat(Infinity)).subscribe({
      next: () => {
        this.currentTimer--;
        if (this.currentTimer <= 0) {
          this.logout();
        }
      },
    });
  }

  refreshTimer(): void {
    this.currentTimer = this.maxTimerInMinutes;
  }

  private logout(): void {
    this.authService.logout();
  }
}
