import { Injectable } from '@angular/core';
import { PatientTab } from '@clinician/common/enums/patient-tab';
import { SettingsTab } from '@clinician/common/enums/settings-tab';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  navigationVisible: BehaviorSubject<boolean>;
  sidebarVisible: BehaviorSubject<boolean>;
  settingsTab: BehaviorSubject<SettingsTab>;
  patientTab: BehaviorSubject<PatientTab>;

  constructor() {
    this.navigationVisible = new BehaviorSubject<boolean>(true);
    this.sidebarVisible = new BehaviorSubject<boolean>(false);
    this.settingsTab = new BehaviorSubject<SettingsTab>(SettingsTab.ACCOUNT);
    this.patientTab = new BehaviorSubject<PatientTab>(
      PatientTab.PATIENT_DETAILS,
    );
  }

  hideNavigation(): void {
    this.navigationVisible.next(false);
  }

  showNavigation(): void {
    this.navigationVisible.next(true);
  }

  hideSidebar(): void {
    this.sidebarVisible.next(false);
  }

  showSidebar(): void {
    this.sidebarVisible.next(true);
  }

  setPatientTab(currentTab: PatientTab): void {
    this.patientTab.next(currentTab);
  }

  setSettingsTab(currentTab: SettingsTab): void {
    this.settingsTab.next(currentTab);
  }
}
