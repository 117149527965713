<div class="flex">
  <div
    class="w-3"
    [ngClass]="{
      'bg-nc-green-700': this.data.type === 'success',
      'bg-nc-red-700': this.data.type === 'error',
      'bg-nc-orange-500': this.data.type === 'warning',
      'bg-nc-blue-800': this.data.type === 'info',
    }"
  ></div>
  <div class="flex-1 overflow-hidden p-4">
    <div class="flex flex-col gap-1">
      <div class="flex flex-auto items-center">
        <nc-icon class="mr-3" [icon]="iconMapping[data.type]"></nc-icon>
        <h4 class="mat-subtitle-2 mr-2">
          @if (
            this.data.variant === 'detailed' ||
            (this.data.variant === 'action' && this.data.title)
          ) {
            {{ this.data.title }}
          } @else {
            {{ this.data.message }}
          }
        </h4>
        <nc-button
          class="ml-auto"
          variant="basic"
          [icon]="NcIcon.CrossSmall"
          size="sm"
          (click)="dismiss()"
        ></nc-button>
      </div>
      @if (
        this.data.variant === 'detailed' ||
        (this.data.variant === 'action' && this.data.title)
      ) {
        @if (Array.isArray(this.data.message)) {
          <ul class="ml-2">
            @for (message of this.data.message; track message) {
              <li class="list-inside list-disc">{{ message }}</li>
            }
          </ul>
        } @else {
          <p class="pr-8">{{ this.data.message }}</p>
        }
      }
      @if (
        this.data.variant === 'action' &&
        this.data.actionButtonLabel &&
        this.data.action
      ) {
        <div matSnackBarActions class="self-end">
          <nc-button
            matSnackBarAction
            color="primary"
            variant="basic"
            (buttonClick)="this.data.action()"
            [label]="this.data.actionButtonLabel"
          ></nc-button>
        </div>
      }
    </div>
  </div>
</div>
