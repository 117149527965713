import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { NotificationType } from '../../types/notification-type';
import { NcSnackBarComponent } from './snackbar.component';
import { NcSnackBarData } from './snackbar.types';

const DEFAULT_DURATION_MS = 5000;

@Injectable({
  providedIn: 'root',
})
export class NcSnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBarWithData(
    snackBarData: NcSnackBarData,
  ): MatSnackBarRef<NcSnackBarComponent> {
    return this.snackBar.openFromComponent(NcSnackBarComponent, {
      ...(snackBarData.disableAutoHide
        ? {}
        : {
            duration: snackBarData.durationMs || DEFAULT_DURATION_MS,
          }),
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: `nc-snack-bar-type__${snackBarData.type}`,
      data: {
        title: snackBarData.title,
        message: snackBarData.message,
        type: snackBarData.type,
        variant: snackBarData.variant || 'default',
        actionButtonLabel: snackBarData.actionButtonLabel,
        action: snackBarData.action,
        dismiss: () => this.snackBar.dismiss(),
      },
    });
  }

  openSnackBar(message: string, type: NotificationType) {
    return this.openSnackBarWithData({
      message,
      type,
    });
  }
}
