import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatMenuPanel, MatMenuTrigger } from '@angular/material/menu';

import { Size } from '../../types/size';
import { NcIcon, NcIconComponent } from '../icon';

@Component({
  selector: 'nc-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, NcIconComponent, MatMenuTrigger],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class NcButtonComponent implements OnInit {
  @Input() color: Exclude<ThemePalette, 'warn'> = undefined;
  @Input() variant: 'flat' | 'basic' | 'stroked' = 'flat';
  @Input() size: Exclude<Size, '2xl'> = 'md';
  @Input() icon?: NcIcon;
  @Input() iconPosition: 'leading' | 'trailing' = 'leading';
  @Input() label?: string;
  @Input() disabled = false;
  @Input() floating = false;
  @Input() floatingPosition: 'top' | 'bottom' = 'bottom';
  @Input() fullWidth = false;
  @Input() tabIndex = 0;
  @Input() matMenuTriggerFor: MatMenuPanel | null = null;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  @Output() buttonClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  @HostBinding('class')
  get elementClasses(): string {
    let classes = '';
    if (this.floating) {
      this.floatingPosition === 'bottom'
        ? (classes += 'fixed bottom-10 right-10 z-100')
        : (classes += 'fixed top-8 right-6 z-100');
    }

    if (this.fullWidth) {
      classes += 'block w-full';
    }

    return classes.trimEnd();
  }

  ngOnInit(): void {
    if (!this.label && !this.icon) {
      throw new Error('Either label, icon or both need to be set.');
    }
  }

  handleButtonClick(event: MouseEvent) {
    if (!this.disabled) {
      this.buttonClick.emit(event);
    }
  }

  getButtonClasses() {
    const classes: string[] = ['rounded-full', 'leading-none'];

    classes.push(...this.getSizeClasses());

    if (this.variant === 'flat') {
      if (this.color === 'primary') {
        classes.push(
          'hover:bg-nc-blue-800',
          'active:bg-nc-blue-900',
          'disabled:bg-nc-gray-200',
          'disabled:text-nc-white',
        );
      } else if (this.color === 'accent') {
        classes.push(
          'hover:bg-nc-gray-200',
          'active:bg-nc-gray-300',
          'disabled:bg-nc-gray-50',
          'disabled:text-nc-gray-200',
        );
      }
    } else if (this.variant === 'stroked') {
      classes.push(
        'border-none',
        'ring-inset',
        'ring-inset',
        'hover:bg-nc-blue-50',
        'hover:text-nc-blue-800',
        'hover:ring-nc-blue-800',
        'active:ring-nc-blue-900',
        'active:text-nc-blue-900',
        'active:bg-nc-blue-100',
        'disabled:ring-nc-gray-200',
        'disabled:text-nc-gray-200',
      );
      if (this.color === 'primary') {
        classes.push('ring-2', 'ring-nc-blue-600');
      } else if (this.color === undefined) {
        classes.push('ring-1', 'ring-nc-gray-900', 'font-medium');
      }
    } else if (this.variant === 'basic') {
      classes.push(
        'hover:text-nc-blue-800',
        'active:text-nc-blue-900',
        'disabled:text-nc-gray-200',
      );
      if (this.color === 'primary') {
        classes.push('hover:bg-nc-blue-50', 'active:bg-nc-blue-100');
      } else if (this.color === undefined) {
        classes.push('hover:bg-nc-gray-50', 'active:bg-nc-gray-100');
      }
    }

    if (this.floating) {
      classes.push('shadow-xl');
    }

    if (this.fullWidth) {
      classes.push('w-full');
    }

    return classes;
  }

  getSizeClasses() {
    const classes: string[] = [];

    if (!this.label) {
      classes.push('min-w-0', 'p-0');

      if (this.size === 'sm') {
        classes.push('h-8', 'w-8', 'nc-button-small');
      } else if (this.size === 'md') {
        classes.push('h-10', 'w-10', 'nc-button-medium');
      } else if (this.size === 'lg') {
        classes.push('h-12', 'w-12', 'nc-button-large');
      }
    } else {
      if (this.size === 'sm') {
        classes.push('h-8', 'px-4', 'text-sm', 'nc-button-small');
        if (
          (this.variant === 'flat' && this.color === 'accent') ||
          (this.variant === 'basic' && this.color === 'primary') ||
          (this.variant === 'basic' && this.color === undefined)
        ) {
          classes.push('font-medium');
        }
      } else if (this.size === 'md') {
        classes.push('h-10', 'px-6', 'text-sm', 'nc-button-medium');
        if (
          (this.variant === 'flat' && this.color === 'accent') ||
          (this.variant === 'basic' && this.color === 'primary') ||
          (this.variant === 'basic' && this.color === undefined)
        ) {
          classes.push(' font-medium');
        }
      } else if (this.size === 'lg') {
        classes.push('h-12', 'px-8', 'nc-button-large');
      } else if (this.size === 'xl') {
        classes.push('h-14', 'px-6', 'text-xl', 'nc-button-xlarge');
      }
    }
    return classes;
  }
}
