<mat-sidenav-container class="min-w-1024" [autosize]="true">
  <mat-sidenav
    mode="side"
    [opened]="visibilityService.navigationVisible | async"
    [disableClose]="true"
    [fixedInViewport]="true"
    class="bg-nc-darkblue-700 w-20 print:hidden print:w-0"
  >
    <app-navigation-panel></app-navigation-panel>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'mr-[20%] print:ml-0 print:mr-0':
        this.visibilityService.sidebarVisible | async,
    }"
  >
    <router-outlet (activate)="stopwatchService.refreshTimer()"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
@if (showFeatureFlagToggle) {
  <div class="z-100 fixed bottom-0 right-0 flex gap-2 bg-white">
    <button (click)="toggleFeature('tms')">
      TMS:
      {{ (this.featureFlagService.getFeature('tms') | async) ? 'ON' : 'OFF' }}
    </button>
    <button (click)="toggleFeature('questionnaires')">
      QST:
      {{
        (this.featureFlagService.getFeature('questionnaires') | async)
          ? 'ON'
          : 'OFF'
      }}
    </button>
    <button (click)="toggleFeature('sleep')">
      SLP:
      {{ (this.featureFlagService.getFeature('sleep') | async) ? 'ON' : 'OFF' }}
    </button>
  </div>
}
