@if (variant === 'flat') {
  <button
    mat-flat-button
    [color]="color"
    [disabled]="disabled"
    [ngClass]="getButtonClasses()"
    [tabIndex]="tabIndex"
    (click)="handleButtonClick($event)"
    [matMenuTriggerFor]="matMenuTriggerFor"
    [type]="type"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
} @else if (variant === 'stroked') {
  <button
    mat-stroked-button
    [color]="color"
    [disabled]="disabled"
    [ngClass]="getButtonClasses()"
    [tabIndex]="tabIndex"
    (click)="handleButtonClick($event)"
    [matMenuTriggerFor]="matMenuTriggerFor"
    [type]="type"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
} @else {
  <button
    mat-button
    [color]="color"
    [disabled]="disabled"
    [ngClass]="getButtonClasses()"
    [tabIndex]="tabIndex"
    (click)="handleButtonClick($event)"
    [matMenuTriggerFor]="matMenuTriggerFor"
    [type]="type"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
}

<ng-template #buttonContent>
  @if (icon && iconPosition === 'leading') {
    <nc-icon ngClass="flex" [icon]="icon" [size]="size"></nc-icon>
  }
  {{ label }}
  @if (icon && iconPosition === 'trailing') {
    <nc-icon ngClass="flex" [icon]="icon" [size]="size"></nc-icon>
  }
</ng-template>
