<div class="mt-4">
  <div class="flex justify-center">
    <img class="object-cover" src="/assets/logos/text-logo.svg" alt="404" />
  </div>
  <div class="mt-44 flex justify-center">
    <img class="object-cover" src="/assets/images/404-computer.svg" alt="404" />
  </div>
  <div class="mt-8">
    <h1 class="text-nc-darkblue-700 text-center text-3xl">404 Not Found</h1>
    <h3 class="text-nc-darkblue-700 mt-2 flex justify-center">
      Sorry, we can't find the page you're looking for.
    </h3>
  </div>
  <div class="mt-6 grid grid-cols-9">
    <div class="col-start-5 justify-self-center">
      <nc-button
        color="primary"
        size="lg"
        (buttonClick)="backToHomePage()"
        label="Back to homepage"
      ></nc-button>
    </div>
  </div>
</div>
