import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found-component',
  templateUrl: './not-found-component.component.html',
})
export class NotFoundComponentComponent {
  constructor(readonly router: Router) {}

  backToHomePage(): void {
    this.router.navigate(['/patient']);
  }
}
