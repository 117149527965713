import { Injectable, Injector } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, IdTokenClaims } from '@azure/msal-browser';
import { PermissionListDTO } from '@clinician/common/dtos/permission-list';
import {
  ClinicReportClaim,
  PatientsClaim,
} from '@clinician/common/enums/claims';
import { environment } from '@clinician_environment';
import { BehaviorSubject, Observable, of } from 'rxjs';

interface TokenClaims extends IdTokenClaims {
  extension_ClinicGroupId?: string;
  extension_ClinicReportPermission?: number;
  extension_PatientsPermission?: number;
  extension_IsAdmin?: boolean;
  extension_Title?: string;
  given_name?: string;
  family_name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  patients$$: BehaviorSubject<PatientsClaim> =
    new BehaviorSubject<PatientsClaim>(PatientsClaim.NoAccess);

  clinicReport$$: BehaviorSubject<ClinicReportClaim> =
    new BehaviorSubject<ClinicReportClaim>(ClinicReportClaim.NoAccess);

  adminStatus$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  clinicGroupId$$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);

  username: string[];
  authService: MsalService;
  loggedAccountId: string;

  constructor(readonly injector: Injector) {
    if (environment.b2cTenant.enabled) {
      this.authService = this.injector.get(MsalService);
      this.refreshClaims();
    }
  }

  refreshClaims(): void {
    const authInstance = this.authService.instance.getAllAccounts()[0];
    if (authInstance?.idTokenClaims) {
      const claims: TokenClaims = authInstance.idTokenClaims;
      this.loggedAccountId = authInstance.localAccountId;

      this.patients$$.next(
        (claims?.extension_PatientsPermission as number) ??
          PatientsClaim.NoAccess,
      );

      this.clinicReport$$.next(
        (claims?.extension_ClinicReportPermission as number) ??
          ClinicReportClaim.NoAccess,
      );

      this.adminStatus$$.next((claims?.extension_IsAdmin as boolean) ?? false);
      this.clinicGroupId$$.next(claims?.extension_ClinicGroupId);
      this.username = this.buildUsername(authInstance);
    }
  }

  buildUsername(authInstance: AccountInfo): string[] {
    const claims: TokenClaims | undefined = authInstance.idTokenClaims;
    const title = claims?.extension_Title || '';
    return [
      title as string,
      `${claims?.given_name as string} ${claims?.family_name as string}`,
    ];
  }

  getListOfPermissions(): Observable<PermissionListDTO> {
    return of({
      id: '',
      clinicReport: ['No access', 'View only'],
      patients: [
        'No access',
        'View only',
        'Edit',
        'Full Access (including TMS Treatment)',
      ],
      stimware: ['No access', 'Viewer', 'Operator', 'Creator'],
    });
  }
}
