import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  ClinicReportClaim,
  PatientsClaim,
} from '@clinician/common/enums/claims';
import { PermissionsService } from '@clinician/services/permissions/permissions.service';
import { NcSnackBarService } from '@neurocare/ui';

export const canActivateClinicReport = () => {
  const permissionsService = inject(PermissionsService);
  const snackBarService = inject(NcSnackBarService);
  const router = inject(Router);
  const hasAccess =
    permissionsService.clinicReport$$.value !== ClinicReportClaim.NoAccess;
  if (!hasAccess) {
    snackBarService.openSnackBar(
      'You do not have permissions to view the Clinic Report.',
      'error',
    );
    if (permissionsService.patients$$.value === PatientsClaim.NoAccess) {
      return router.navigateByUrl('/setting');
    } else {
      return router.navigateByUrl('/patient');
    }
  }
  return hasAccess;
};
