import { FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

export const minSelectedCheckboxes = (
  min = 1,
  errorName: keyof ValidationErrors,
  propertyName?: string,
): ValidatorFn => {
  return (control): ValidationErrors | null => {
    const formArray = control as FormArray;
    const totalSelected: number = formArray.controls
      .map((control) =>
        propertyName ? control.value[propertyName] : control.value,
      )
      .reduce((prev, next) => (next ? prev + 1 : prev), 0);
    return totalSelected >= min ? null : { [errorName]: true };
  };
};
