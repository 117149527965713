export enum ClinicReportClaim {
  NoAccess = 1,
  View = 2,
}

export enum PatientsClaim {
  NoAccess = 1,
  View = 2,
  Edit = 3,
  FullAccess = 4,
}

export enum StimwareClaim {
  NoAccess = 1,
  Viewer = 2,
  Operator = 3,
  Creator = 4,
}
