import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { VisibilityService } from '@clinician/services/core/visibility.service';
import { PermissionsService } from '@clinician/services/permissions/permissions.service';
import { environment } from '@clinician_environment';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-landing',
  template: '',
})
export class LandingComponent implements OnInit, OnDestroy {
  private readonly destroying$ = new Subject<void>();
  readonly subs: Subscription[] = [];
  readonly msalBroadcastService: MsalBroadcastService;
  readonly authService: MsalService;

  constructor(
    readonly injector: Injector,
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly permissionService: PermissionsService,
    readonly visibilityService: VisibilityService,
  ) {
    if (environment.b2cTenant.enabled) {
      this.authService = this.injector.get(MsalService);
      this.msalBroadcastService = this.injector.get(MsalBroadcastService);
    }
  }

  ngOnInit(): void {
    this.visibilityService.hideNavigation();
    if (environment.b2cTenant.enabled) {
      const sub = this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None,
          ),
          takeUntil(this.destroying$),
        )
        .subscribe(() => {
          if (this.authService.instance.getAllAccounts().length === 0) {
            this.authService.loginRedirect();
          } else {
            this.permissionService.refreshClaims();
            this.router.navigate(['/patient']);
          }
        });
      this.subs.push(sub);
    } else {
      this.router.navigate(['/patient']);
    }
  }

  ngOnDestroy(): void {
    this.destroying$.next();
    this.destroying$.complete();
    this.subs.map((sub) => sub.unsubscribe());
    this.visibilityService.showNavigation();
  }
}
