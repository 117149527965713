import { inject } from '@angular/core';
import {
  FeatureFlagKeys,
  FeatureFlagService,
} from '@clinician/services/core/feature-flag.service';

export const featureFlagGuard = (feature: FeatureFlagKeys) => {
  return () => {
    const featureFlagService = inject(FeatureFlagService);
    return featureFlagService.getFeature(feature);
  };
};
