import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LandingComponent } from '@clinician/features/landing/landing.component';
import { NotFoundComponentComponent } from '@clinician/features/not-found-component/not-found-component.component';
import { canActivateClinicReport } from '@clinician/route-guards/permissions/can-activate-clinic-report';
import { canActivatePatients } from '@clinician/route-guards/permissions/can-activate-patient';
import { environment } from '@clinician_environment';

import { featureFlagGuard } from './route-guards/feature-flag.guard';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const guards: any[] = environment.b2cTenant.enabled ? [MsalGuard] : [];
const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    // Needed for hash routing
    path: 'error',
    component: LandingComponent,
  },
  {
    // Needed for hash routing
    path: 'state',
    component: LandingComponent,
  },
  {
    path: 'patient',
    canActivate: [...guards, canActivatePatients],
    loadChildren: () =>
      import('@clinician/features/patient/patient.module').then(
        (m) => m.PatientModule,
      ),
  },
  {
    path: 'clinic-report',
    canActivate: [...guards, canActivateClinicReport, featureFlagGuard('tms')],
    loadChildren: () =>
      import('@clinician/features/clinic-report/clinic-report.module').then(
        (m) => m.ClinicReportModule,
      ),
  },
  {
    path: 'setting',
    canActivate: guards,
    loadChildren: () =>
      import('@clinician/features/settings/settings.module').then(
        (m) => m.SettingsModule,
      ),
  },

  {
    path: '**',
    component: NotFoundComponentComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      paramsInheritanceStrategy: 'always',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
